// Contains style overwrites that cannot be done by mui or other

// Dark mode overwrites
.DarkMode {
  .MuiSkeleton-root {
    background-color: rgba(255, 255, 255, 0.11) !important;

    &:after {
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.05),
        transparent
      ) !important;
    }
  }
}
