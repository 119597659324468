@import "./node_modules/react-vis/dist/style";
@import "~constants/index.scss";

@font-face {
  font-family: "Gilroy";
  src: url("./assets/font/Gilroy-Medium.eot") format("embedded-opentype"),
    url("./assets/font/Gilroy-Medium.woff") format("woff"),
    url("./assets/font/Gilroy-Medium.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/font/Gilroy-Regular.eot") format("embedded-opentype"),
    url("./assets/font/Gilroy-Regular.woff") format("woff"),
    url("./assets/font/Gilroy-Regular.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/font/Gilroy-Bold.eot") format("embedded-opentype"),
    url("./assets/font/Gilroy-Bold.woff") format("woff"),
    url("./assets/font/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: 'Noe display';
  src: url('./assets/font/noe-display-bold.woff2') format('woff2'), url('./assets/font/noe-display-bold.woff') format('woff'), url('./assets/font/noe-display-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Noe display';
  src: url('./assets/font/noe-display-regular.woff2') format('woff2'), url('./assets/font/noe-display-regular.woff') format('woff'), url('./assets/font/noe-display-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./assets/font/ProximaNova-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./assets/font/ProximaNova-Bold.otf') format('opentype'), url('./assets/font/ProximaNova-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./assets/font/ProximaNova-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./assets/font/ProximaNova-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./assets/font/ProximaNova-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Audiowide';
  src: url('./assets/font/Audiowide-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.content {
  padding-top: 14px;
  margin-left: 110px;

  @media screen and (max-width: $md) {
    margin-left: 0px;
    background-color: "transparent";
    margin-bottom: 0px;
  }
}

.discrete-scrollbar::-webkit-scrollbar {
  width: 5px;
} .discrete-scrollbar::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
} .discrete-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.420);
  border-radius:5px;
}