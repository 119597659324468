@import "~constants/index.scss";

.Navigation {
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: column;
  position: fixed !important;
  z-index: 999;

  @media screen and (max-width: $md) {
    border-top: 1px solid $card_paper_color;
    z-index: 999;
    height: $bottom_nav_size;
    width: 100vw;
    flex-direction: row;
    align-items: center;
    bottom: 0;
    background-color: $background_color;

    .navItem {
      border-left: none !important;
    }
  }

  .navItem {
    margin: 12px 0;
    justify-content: center;
    cursor: pointer;
  }

  @media screen and (min-width: $md) {
    .navItem:last-child {
      margin-top: auto;
    }
  }
}
